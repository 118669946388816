import { useStyles } from './dashboard.hooks';
import { getCurrentUser } from 'services';
import { useEffect } from 'react';
import { AdminDashboard } from './components/AdminDashboard';
import CampaignDashboard from './components/CampaignDashboard';
import { entitiesEnum } from 'constants';

export const TableDashboard = ({ entity }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchUserRole();
  }, []);

  const fetchUserRole = async () => {
    try {
      const data = await getCurrentUser();

      if (data) {
        localStorage.setItem('userRoles', data.data.group);
        // dispatch(setUserRoles(data.data.group));
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {entity === entitiesEnum.CAMPAIGN ? <CampaignDashboard /> : <AdminDashboard />}
      </div>
    </div>
  );
};
