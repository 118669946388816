import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DynamicTable from './DynamicTable';
import { getCurrentUser } from 'services';
import { capitalizeString } from 'utils';

const CampaignDashboard = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filtersSchema, setFiltersSchema] = useState(null);
  const [operations, setOperations] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const navigate = useNavigate();
  const [selectedField, setSelectedField] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchAccessToken = async () => {
    try {
      const user = await getCurrentUser();
      setAccessToken(user.accessToken);

      if (user) {
        fetchCampaignMenu(user.accessToken);
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  const formatString = str =>
    str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w|\s\w/g, match => match.toUpperCase());

  const fetchCampaignMenu = async token => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_STATEMENT_URL}/tt/admin-menu`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      const resource = data.resources[0];
      setBaseUrl(resource.baseUrl);

      const ops = Object.values(resource.operations);
      setOperations(ops);

      const schemaResponse = await fetch(
        `${process.env.REACT_APP_STATEMENT_URL}/tt${resource.schemaUrl}`,
        {
          headers: {
            Accept: 'application/schema+json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const schemaData = await schemaResponse.json();

      if (schemaData) {
        const tableColumns = Object.entries(schemaData.properties).map(([key, value]) => ({
          field: key,
          headerName: formatString(value.localization),
          flex: 1,
        }));

        tableColumns.push({
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          renderCell: params => (
            <Box
              display="flex"
              gap={1}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              {ops.map(operation => {
                if (operation.externalActions) {
                  return operation.externalActions.map(action => (
                    <Button
                      key={action.url}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ fontSize: '1rem' }}
                      onClick={() =>
                        handleActionClick(action, params.row.id, resource.baseUrl, token)
                      }
                    >
                      {action.label}
                    </Button>
                  ));
                }

                if (operation.operationType === 'DELETE') {
                  return (
                    <Button
                      key={operation.operationType}
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleDelete(params.row.id, resource.baseUrl)}
                    >
                      Delete
                    </Button>
                  );
                }

                return null;
              })}
            </Box>
          ),
        });

        setFiltersSchema(resource.operations.LIST?.filtersSchema);
        setColumns(tableColumns);
        if (resource.operations.LIST?.filtersSchema) {
          const firstField = Object.keys(resource.operations.LIST.filtersSchema.properties)[0];
          setSelectedField(firstField);
        }
      }
    } catch (error) {
      console.error('Failed to fetch campaign menu:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRows = async (page, pageSize, url = '', token = '') => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_STATEMENT_URL}/tt${
          baseUrl === '' ? url : baseUrl
        }?${selectedField}=${searchValue}&page=${page}&size=${pageSize}`,
        {
          headers: { Authorization: `Bearer ${accessToken === '' ? token : accessToken}` },
        }
      );
      if (!response.ok) {
        setRows([]);
        return;
      }
      const data = await response.json();

      const transformedData = data.content.map(item => ({
        ...item,
        businessName: item.businessName ? capitalizeString(item.businessName) : item.businessName,
      }));

      setRows(transformedData);
      setRowCount(data.totalPages || 0);
      if (data.pageable) {
        setPage(data.pageable.pageNumber);
        setPageSize(data.pageable.pageSize);
      }
    } catch (error) {
      console.error('Failed to fetch rows:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleActionClick = async (action, id, baseUrl, token) => {
    if (action.type === 'BACKGROUND_CALL' && action.url) {
      setActionLoading(true);

      try {
        const url = `${process.env.REACT_APP_STATEMENT_URL}/tt${baseUrl}${action.url}/${id}`;
        const method = action.method;

        const response = await fetch(url, {
          method,
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error(`Failed to execute action: ${action.label}`);
        }

        fetchRows(page, pageSize, baseUrl, token);
      } catch (error) {
        setActionLoading(false);
        console.error(`Error executing background call: ${error.message}`);
      } finally {
        setActionLoading(false);
      }
    }
  };

  const handleDelete = async (id, url) => {
    try {
      await fetch(`${process.env.REACT_APP_STATEMENT_URL}   ${url}/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      fetchRows(page, pageSize);
    } catch (error) {
      console.error('Failed to delete row:', error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };
  const handlePageSizeChange = event => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    fetchRows(0, newPageSize);
  };
  const handleSearch = () => fetchRows(0, pageSize);
  const handleFieldChange = event => {
    setSelectedField(event.target.value);
  };

  useEffect(() => {
    fetchAccessToken();
  }, []);

  useEffect(() => {
    if (columns.length > 0) {
      fetchRows(page, pageSize);
    }
  }, [page, pageSize, columns]);

  return (
    <Box>
      <DynamicTable
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSearch={handleSearch}
        filtersSchema={filtersSchema}
        selectedField={selectedField}
        onFieldChange={handleFieldChange}
        actionLoading={actionLoading}
        onSearchValueChange={e => setSearchValue(e.target.value)}
      />
    </Box>
  );
};

export default CampaignDashboard;
