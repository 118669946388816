import React, { useEffect, useState } from 'react';

import { entitiesTitles } from '../../constants';
import { graphqlApiDecorator } from '../../decorators';
import { mutations } from '../../graphql';
import { NewPlusIcon } from '../icons';
import { SavedModal, WarningModal } from '../modal/saved-warning-modal';
import { useStyles } from './admin-team-page.hooks';
// import { Activities } from './components/activities';
import { AdminList } from './components/adminList';
import { adminRows } from './mockData';
import { AddNewMemberModal } from './sub-components/add-new-member-modal';

export const AdminTeamPage = () => {
  const [adminListSeed, setAdminListSeed] = useState(1);
  const [openAddNewMemberModal, setOpenAddNewMemberModal] = useState(false);
  const [openConfirmNewMemberModal, setOpenConfirmNewMemberModal] = useState(false);
  const [openErrorNewMemberModal, setOpenErrorNewMemberModal] = useState(false);
  const [membersData, setMembersData] = useState([]);
  const classes = useStyles();

  const reset = () => {
    setAdminListSeed(Math.random());
  };

  document.title = entitiesTitles.Team;

  useEffect(() => {
    sessionStorage.setItem('currentPageCustomers', 0);
    sessionStorage.setItem('currentPageTransactions', 0);
    // TODO: Using mock data for members but need to call BE endPoint for members
    setMembersData(adminRows);
  }, []);

  const handleUserCreateSubmit = async userData => {
    try {
      const result = await graphqlApiDecorator(mutations, 'createUser', { input: userData });
      setOpenAddNewMemberModal(false);

      if (result.error) {
        setOpenErrorNewMemberModal(true);
      } else {
        setOpenConfirmNewMemberModal(true);
        reset();
      }
    } catch (error) {
      console.log(error);
      setOpenErrorNewMemberModal(true);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <div className={classes.label}>Team</div>
        <button
          type="button"
          className={classes.addNewMemberBtn}
          onClick={() => setOpenAddNewMemberModal(true)}
        >
          <NewPlusIcon width="24px" height="24px" color="#19365e" />
          Add New Member
        </button>
      </div>
      <div className={classes.content}>
        <AdminList data={membersData} key={adminListSeed} />
        {/* <Activities /> */}
      </div>
      {openAddNewMemberModal ? (
        <AddNewMemberModal
          onClose={() => setOpenAddNewMemberModal(false)}
          onSubmit={handleUserCreateSubmit}
        />
      ) : null}
      {openConfirmNewMemberModal ? (
        <SavedModal onClose={() => setOpenConfirmNewMemberModal(false)} content="User created" />
      ) : null}
      {openErrorNewMemberModal ? (
        <WarningModal
          confirm={() => setOpenErrorNewMemberModal(false)}
          message="User create failed"
        />
      ) : null}
    </div>
  );
};
