import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DynamicTable from './DynamicTable';
import { getCurrentUser } from 'services';
import { capitalizeString } from 'utils';

export const AdminDashboard = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filtersSchema, setFiltersSchema] = useState(null);
  const [operations, setOperations] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const navigate = useNavigate();
  const [selectedField, setSelectedField] = useState('');
  const [searchValue, setSearchValue] = useState('');

  // Fetch user access token
  const fetchAccessToken = async () => {
    try {
      const user = await getCurrentUser();
      setAccessToken(user.accessToken);

      if (user) {
        fetchAdminMenu(user.accessToken);
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  const formatString = str =>
    str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w|\s\w/g, match => match.toUpperCase());

  // Fetch admin menu and schema
  const fetchAdminMenu = async token => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_STATEMENT_URL}/user/admin-menu`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      const resource = data.resources[0];
      setBaseUrl(resource.baseUrl);

      // Extract operations
      const ops = Object.values(resource.operations);
      setOperations(ops);

      // Fetch schema for columns
      const schemaResponse = await fetch(
        `${process.env.REACT_APP_STATEMENT_URL}/user${resource.schemaUrl}`,
        {
          headers: {
            Accept: 'application/schema+json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const schemaData = await schemaResponse.json();

      if (schemaData) {
        const tableColumns = Object.entries(schemaData.properties).map(([key, value]) => ({
          field: key,
          headerName: formatString(value.localization),
          flex: 1,
        }));

        // Add actions column with dynamic buttons for each row
        tableColumns.push({
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          renderCell: params => (
            <Box
              display="flex"
              gap={1}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              {ops.map(operation => {
                // Handle external actions
                if (operation.operationType === 'EXTERNAL' && operation.externalActions) {
                  return operation.externalActions.map(action => (
                    <Button
                      key={action.url}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() =>
                        handleActionClick(action, params.row.id, data.resources[0].baseUrl)
                      }
                    >
                      {action.label}
                    </Button>
                  ));
                }

                // Skip LIST operation type
                if (operation.operationType === 'LIST') {
                  return null;
                }

                // Handle other operations
                return (
                  <Button
                    key={operation.operationType}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() =>
                      handleOperationClick(operation.operationType, params.row.id, baseUrl)
                    }
                  >
                    {operation.operationType}
                  </Button>
                );
              })}
            </Box>
          ),
        });

        setFiltersSchema(resource.operations.LIST?.filtersSchema);
        setColumns(tableColumns);
        if (resource.operations.LIST?.filtersSchema) {
          const firstField = Object.keys(resource.operations.LIST.filtersSchema.properties)[0];
          setSelectedField(firstField);
        }
      }
    } catch (error) {
      console.error('Failed to fetch admin menu:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch rows for the table
  const fetchRows = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_STATEMENT_URL}/user${baseUrl}?${selectedField}=${searchValue}&page=${page}&size=${pageSize}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      const data = await response.json();

      const transformedData = data.map(item => ({
        ...item,
        accountType: item.accountType ? capitalizeString(item.accountType) : item.accountType,
        boardStatus: item.boardStatus ? formatString(item.boardStatus) : item.boardStatus,
      }));

      setRows(transformedData);
      setRowCount(data.length);
    } catch (error) {
      console.error('Failed to fetch rows:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleActionClick = (action, id, url) => {
    if (action.type === 'ROUTE' && action.url) {
      navigate(`${action.url}?id=${id}&baseUrl=${url}&tag=${action.operationType}`);
    } else {
      console.log(`Unhandled action type: ${action.operationType}`);
    }
  };

  // Handle pagination and search
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handlePageSizeChange = event => setPageSize(parseInt(event.target.value, 10));
  const handleSearch = () => fetchRows(0, pageSize);

  const handleFieldChange = event => {
    setSelectedField(event.target.value);
  };

  // Initial data fetch
  useEffect(() => {
    fetchAccessToken();
  }, []);

  useEffect(() => {
    if (columns.length > 0) {
      fetchRows(page, pageSize);
    }
  }, [page, pageSize, columns]);

  return (
    <Box>
      <DynamicTable
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        pageSize={pageSize}
        page={page}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSearch={handleSearch}
        filtersSchema={filtersSchema}
        selectedField={selectedField}
        onFieldChange={handleFieldChange}
        onSearchValueChange={e => setSearchValue(e.target.value)}
      />
    </Box>
  );
};

export default AdminDashboard;
