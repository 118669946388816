import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import { EntityTable } from '../../components';
import { useGraphList } from '../../hooks';
import { GenericToolbar } from './components';

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'hidden',
  },
  content: {
    padding: '24px',
    paddingBottom: '0px',
  },
}));

/**
 * This is a General component that will render for entities of USERPROFILE,
 *  TRANSACTIONS, PAIRS, CURRENCIES and COUNTRIES
 * @param {string} entity this is the name of the entity. A full list is in the
 * entitiesEnum Object in src/constants/entities.js
 * @param {Object} listData this contains many properties including the data of the
 * entity. The listData is obtained from the custom hook useGraphList
 */
export const GenericList = ({ entity }) => {
  const classes = useStyles();
  const customerEntitySort = useSelector(state => state.customerSort);
  const listData = useGraphList({ entity, customerEntitySort });

  return (
    <div className={classes.root}>
      <GenericToolbar entity={entity} listData={listData} />

      <div className={classes.content}>
        <EntityTable entity={entity} listData={listData} />
      </div>
    </div>
  );
};
