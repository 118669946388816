import { getCurrentUser } from 'services';

let entitiesEnum = {
  USERPROFILE: 'UserProfile',
  TEAM: 'Team',
  MEMBERSHIP: 'Membership',
  NOTIFICATION: 'Notification',
  TRANSACTION: 'Transaction',
  TRANSACTIONV2: 'TransactionV2',
  ONBOARDING: 'Onboarding',
  PAIR: 'Pair',
  DISCOUNT: 'Discount',
  CURRENCY: 'Currency',
  COUNTRY: 'Country',
  REGION: 'Region',
  CONFIG: 'Config',
  FEE: 'Fee',
  LOCALPAIR: 'LocalPair',
  MCCYBalances: 'MCCYBalances',
  METRIC: 'Metric',
  KYB: 'KYB',
  TABLE: 'TABLE',
  FORM: 'FORM',
  MULTICURRENCY: 'MultiCurrency',
  CAMPAIGN: 'Campaign',
};

let entitiesTitles = {
  UserProfile: 'BackOffice - Customers',
  Transaction: 'BackOffice - Transactions',
  Pair: 'BackOffice - Pairs',
  Currency: 'BackOffice - Currencies',
  Country: 'BackOffice - Countries',
  Team: 'BackOffice - Teams',
  MultiCurrency: 'BackOffice - Multi Currencies',
  Metric: 'BackOffice - Metrics Dashboard',
  KYB: 'BackOffice - KYB Dashboard',
  TABLE: 'BackOffice - Companies Dashboard',
  FORM: 'BackOffice - Companies Dashboard',
  CAMPAIGN: 'BackOffice - Campaign Dashboard',
};

export const fetchAndUpdateEntities = async () => {
  try {
    const { accessToken } = await getCurrentUser();

    const response = await fetch(`${process.env.REACT_APP_STATEMENT_URL}/user/admin-menu`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    data.resources.forEach(item => {
      if (!entitiesEnum[item.title.toUpperCase()]) {
        entitiesEnum[item.title.toUpperCase()] = item.title.toUpperCase();
      }
      if (!entitiesTitles[item.title]) {
        entitiesTitles[item.title.toUpperCase()] = `BackOffice - ${item.title}`;
      }
    });
  } catch (error) {
    console.error('Failed to fetch and update entities:', error);
  }
};

fetchAndUpdateEntities();

export { entitiesEnum, entitiesTitles };
