import React, { useEffect, useState } from 'react';
import { useStyles } from './risk-details-modal.hooks';
import { graphqlApiDecorator } from 'decorators';
import { queries } from '../../../../../graphql';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Loader } from 'components/loader';
import { dateAndTime } from 'components/date-and-time';
import { deleteDash } from 'utils/formatString';
import { ArrowLeftIcon } from '@mui/x-date-pickers';

export const RiskEditModal = ({ onClose, id, userId, onClickBackIcon }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleRiskRate = riskRate => {
    switch (riskRate) {
      case 0:
        return 'Low';
      case 1:
        return 'Medium';
      case 2:
        return 'High';
      case 3:
        return 'Very High';
      default:
        return 'Unknown';
    }
  };

  const fetchRisk = async () => {
    const queryTitle = 'getUserRisks';
    const input = { userId, riskId: id };
    setLoading(true);
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { input });
      if (result && result.items) {
        setData(result.items);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFreezDate = freezDate => {
    if (freezDate) {
      const date = new Date(freezDate);
      const formattedDate = date.toISOString().split('T')[0];
      return formattedDate;
    }
    return null;
  };

  useEffect(() => {
    fetchRisk();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.riskModalContainer}>
            <div className={classes.backIconCuntainer}>
              <div onClick={() => onClickBackIcon()}>
                <ArrowLeftIcon className={classes.backIcon} />
              </div>
            </div>
            <div className={classes.firstTable}>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>Risk Rate</TableCell>
                  <TableCell className={classes.tableCell}>Date and Time</TableCell>
                  <TableCell className={classes.tableCell}>Author</TableCell>
                  <TableCell className={classes.tableCell}>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map(item =>
                    item.type === 'OVERALL' ? (
                      <TableRow key={item.riskId} className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          {handleRiskRate(item.riskLevel) || '--'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {dateAndTime(item.updatedAt)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{item.updaterEmail}</TableCell>
                        <TableCell className={classes.tableCell}>{item.updaterComment}</TableCell>
                      </TableRow>
                    ) : null
                  )}
              </TableBody>
            </div>
            <div>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>Criteria</TableCell>
                  <TableCell className={classes.tableCell}>Value</TableCell>
                  <TableCell className={classes.tableCell}>Risk rate</TableCell>
                  <TableCell className={classes.tableCell}>Freezed Until</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map(item =>
                    item.type !== 'OVERALL' ? (
                      <TableRow key={item.riskId} className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          {deleteDash(item.type.toLowerCase()) || '--'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.itemValue || '--'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {handleRiskRate(item.riskLevel) || '--'}
                        </TableCell>
                        <TableCell>{handleFreezDate(item.freezeUntil) || '--'}</TableCell>
                      </TableRow>
                    ) : null
                  )}
              </TableBody>
            </div>
          </div>
        </>
      )}
    </>
  );
};
