import { fontSize, styled } from '@mui/system';
import { TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const CustomTextField = styled(TextField)({
  '.MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
  firstRow: {
    fontWeight: 'bold',
  },
  oddRow: {
    backgroundColor: '#f5f5f5',
  },
});

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    fontWeight: 'bold',
    fontSize: '13px',
  },
  '& .MuiDataGrid-row.even': {
    backgroundColor: theme.palette.action.hover,
    fontSize: '13px',
  },
  '& .MuiDataGrid-row.odd': {
    fontSize: '13px',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));
