import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Pagination,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GridToolbar } from '@mui/x-data-grid';
import { StyledDataGrid } from './Dashboard-content.hook';
import { Loader } from 'components/loader';

const DynamicTable = ({
  columns,
  rows,
  rowCount,
  pageSize,
  page,
  loading,
  onPageChange,
  onPageSizeChange,
  onSearch,
  filtersSchema,
  searchValue,
  onSearchValueChange,
  operations,
  onOperationClick,
  selectedField,
  onFieldChange,
  actionLoading,
}) => {
  const formatString = str =>
    str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w|\s\w/g, match => match.toUpperCase());
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            height: 'auto',
            width: '100%',
            borderRadius: '0 16px 16px 16px',
            padding: '20px 10px',
            background: 'white',
            overflow: 'hidden',
          }}
        >
          {filtersSchema && (
            <Box display="flex" alignItems="center" mb="10px" style={{ borderRadius: '16px' }}>
              <TextField
                variant="outlined"
                placeholder="Search"
                value={searchValue}
                onChange={onSearchValueChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FormControl variant="outlined" size="small">
                        <Select
                          value={selectedField}
                          onChange={onFieldChange}
                          displayEmpty
                          renderValue={selected =>
                            selected
                              ? formatString(filtersSchema.properties[selected].localization)
                              : 'Filter'
                          }
                        >
                          {Object.keys(filtersSchema.properties).map(key => (
                            <MenuItem key={key} value={key}>
                              {formatString(filtersSchema.properties[key].localization)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}

          {operations?.map(
            op =>
              op.operationType === 'CREATE' && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginBottom="20px"
                  key={op.operationType}
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => onOperationClick('CREATE')}
                  >
                    Create
                  </Button>
                </Box>
              )
          )}

          <Box>
            <StyledDataGrid
              rows={rows}
              columns={columns}
              style={{ height: 'calc(60vh)' }}
              paginationMode="server"
              rowCount={rowCount}
              loading={actionLoading}
              checkboxSelection
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              getRowHeight={() => 100}
              getRowClassName={params =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
              }
              hideFooter
            />
            <Box display="flex" marginTop={'20px'} justifyContent={'end'} alignItems="center">
              <TextField
                select
                label="Page Size"
                value={pageSize}
                onChange={onPageSizeChange}
                variant="outlined"
                size="small"
                sx={{ minWidth: 120, marginRight: 2 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </TextField>
              <Pagination count={Math.ceil(rowCount)} page={page + 1} onChange={onPageChange} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

DynamicTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  filtersSchema: PropTypes.object,
  searchValue: PropTypes.string,
  onSearchValueChange: PropTypes.func,
  operations: PropTypes.array,
  onOperationClick: PropTypes.func.isRequired,
};

export default DynamicTable;
