import AppWidgetSummary from '../../../components/app-widget-summary';
import { Container, Grid, Typography } from '@mui/material';
import { SideRectangle } from '../../../components/icons';
import { Chart } from './chart';
import { NumberOfAcceptsThisWeek } from '../../../components/icons/metrics/numberOfAcceptsThisWeek';
import { NumberOfApplicationThisWeek } from '../../../components/icons/metrics/numberOfApplicationThisWeek';
import { NumberOfBusinessAccounts } from '../../../components/icons/metrics/numberOfBusinessAccounts';
import { NumberOfFraudulentApplications } from '../../../components/icons/metrics/numberOfFraudulentApplications';
import { NumberOfHighRiskAccount } from '../../../components/icons/metrics/numberOfHighRiskAccount';
import { NumberOfMidRiskAccount } from '../../../components/icons/metrics/numberOfMidRiskAccount';
import { NumberOfPending } from '../../../components/icons/metrics/numberOfPending';
import { NumberOfPersonalAccounts } from '../../../components/icons/metrics/numberOfPersonalAccounts';
import { NumberOfRejectsThisWeek } from '../../../components/icons/metrics/numberOfRejectsThisWeek';
import { NumberLowRiskAccounts } from '../../../components/icons/metrics/numberOfLowRiskAccounts';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import React, { useEffect, useState } from 'react';

import { NumberPEPS } from '../../../components/icons/metrics/numberPEPS';
import { TotalNumberOfRejects } from '../../../components/icons/metrics/TotalNumberOfRejects';
import { NumberOfPendingThisWeek } from '../../../components/icons/metrics/numberOfPendingThisWeek';
import { TotalNumberOfAcceptedApplications } from '../../../components/icons/metrics/TotalNumberOfAcceptedApplications';
import { TotalNumberOfApplications } from '../../../components/icons/metrics/TotalNumberOfApplications';
import { graphqlApiDecorator } from '../../../decorators';
import { queries } from '../../../graphql';
import { useStyles } from './Dashboard-content.hook';
import { Loader } from '../../../components/loader';

export const DashboardContent = () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    numberOfAcceptsThisWeek: '',
    numberOfApplicationThisWeek: '',
    numberOfBusinessAccounts: '',
    numberOfFraudulentApplications: '',
    numberOfHighRiskAccount: '',
    numberOfLowRiskAccount: '',
    numberOfMidRiskAccount: '',
    numberOfPendingThisWeek: '',
    numberOfPersonalAccounts: '',
    numberOfRejectsThisWeek: '',
    numberPEPS: '',
    totalApplications: '',
    totalNumberOfAccept: '',
    totalNumberOfPending: '',
    totalNumberOfRejects: '',
  });

  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    return await graphqlApiDecorator(queries, 'getApplicationsStatus');
  };

  useEffect(() => {
    sessionStorage.setItem('currentPageCustomers', 0);
    sessionStorage.setItem('currentPageTransactions', 0);
    setLoading(false);
    loadData().then(res => {
      setLoading(false);
      if (res) setValues(res);
    });
  }, []);
  const cards = [
    {
      icon: <NumberOfAcceptsThisWeek />,
      title: 'Number of Accepted Application this week',
      total: values['numberOfAcceptsThisWeek'],
      color: '#ADD8E6',
    },
    {
      icon: <NumberOfPersonalAccounts />,
      title: 'number Of Personal Accounts',
      total: values['numberOfPersonalAccounts'],
      color: '#DFF0F1',
    },
    {
      icon: <NumberOfBusinessAccounts />,
      title: 'Number of Business Account',
      total: values['numberOfBusinessAccounts'],
      color: '#9ACEEB',
    },
    {
      icon: <NumberLowRiskAccounts />,
      title: 'Number of Low Risk Account',
      total: values['numberOfLowRiskAccount'],
      color: '#9ACEEB',
    },
    {
      icon: <NumberOfMidRiskAccount />,
      title: 'Number Of Mid Risk Account',
      total: values['numberOfMidRiskAccount'],
      color: '#AEEAE9',
    },
    {
      icon: <NumberOfHighRiskAccount />,
      title: 'Number Of High Risk Account',
      total: values['numberOfHighRiskAccount'],
      color: '#87CEEB',
    },
    {
      icon: <NumberOfPendingThisWeek />,
      title: 'Number of Pending this week',
      total: values['numberOfPendingThisWeek'],
      color: '#DFF0F1',
    },
    {
      icon: <NumberOfPending />,
      title: 'Total Number of Pending Applications',
      total: values['totalNumberOfPending'],
      color: '#DFF0F1',
    },
    {
      icon: <NumberOfRejectsThisWeek />,
      title: 'Number of Rejects this week',
      total: values['numberOfRejectsThisWeek'],
      color: '#DFF0F1',
    },
    {
      icon: <NumberPEPS />,
      title: 'Number of Politically Exposed Person',
      total: values['numberPEPS'],
      color: '#DFF0F1',
    },
    {
      icon: <NumberOfFraudulentApplications />,
      title: 'Number Of Fraudulent Applications',
      total: values['numberOfFraudulentApplications'],
      color: '#C5D0E6',
    },
    {
      icon: <TotalNumberOfRejects />,
      title: 'Total Number of Rejects Applications',
      total: values['totalNumberOfRejects'],
      color: '#DFF0F1',
    },
    {
      icon: <TotalNumberOfApplications />,
      title: 'Number of Applications this week',
      total: values['numberOfApplicationThisWeek'],
      color: '#DFF0F1',
    },
    {
      icon: <TotalNumberOfAcceptedApplications />,
      title: 'Total Number of Accepted Applications',
      total: values['totalNumberOfAccept'],
      color: '#DFF0F1',
    },
    {
      icon: <NumberOfApplicationThisWeek />,
      title: 'Total Number of Applications',
      total: values['totalApplications'],
      color: '#B0E0E6',
    },
    {
      color: '#DFF0F1',
    },
    {
      color: '#DFF0F1',
    },
    {
      color: '#DFF0F1',
    },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <div className={classes.boxContent}>
            <div className={classes.leftContainer}>
              <SideRectangle color={'#1fe3ac'} />
              <Typography fontWeight={'bold'} variant={'h4'}>
                Application Status
              </Typography>
            </div>
            <Grid className={classes.gridContent} container spacing={3}>
              {cards.map(card => {
                return (
                  <Grid item xs={12} sm={6} md={2} key={card?.title}>
                    <AppWidgetSummary
                      icon={card?.icon}
                      title={card?.title}
                      total={card.total}
                      color={card?.color}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <div className={classes.boxContent}>
            <div className={classes.leftContainer}>
              <SideRectangle color={'#1fe3ac'} />
              <Typography fontWeight={'bold'} variant={'h4'}>
                Metrics
              </Typography>
            </div>
            <Grid className={classes.gridContent} container>
              <Grid item xs={12} mt={2}>
                <Chart
                  numberOfHighRiskAccount={values['numberOfHighRiskAccount']}
                  numberOfLowRiskAccount={values['numberOfLowRiskAccount']}
                  numberOfMidRiskAccount={values['numberOfMidRiskAccount']}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
