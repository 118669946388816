import React, { useEffect, useState } from 'react';

import { graphqlApiDecorator } from '../../../../decorators';
import { mutations } from '../../../../graphql';
import { resetUserPassword } from '../../../../services';
import { InfoInput } from '.';
import { SideRectangle, SmallSave } from './icons';
import { useStyles } from './info.hooks';
import { SavedModal, WarningModal } from './modals';

/**
 * Sub-component of the CustomerDetailPage parent component. This renders the info that
 * the user has saved when creating an account with Kixy. This includes their address, phone no, email etc.
 * The component feature allows the admin to edit the info and save it to the user profile record.
 * @param {object} data the user profile data of type userProfile
 */
export const Info = ({ data }) => {
  const [country, setCountry] = useState(data.country || '');
  const [city, setCity] = useState(data.city || '');
  const [address, setAddress] = useState(data.address || '');
  const [dob, setDob] = useState(data.birthdate || '');
  const [postalCode, setPostalCode] = useState(data.postalCode || '');
  const [phoneCode, setPhoneCode] = useState(data.phoneCode || '');
  const [phoneNumber, setPhoneNo] = useState(data.phoneNumber || '');
  const [email, setEmail] = useState(data.email || '');
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [message, setMessage] = useState('');
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');
  const classes = useStyles();
  const [signUpFinancialDetails, setSignUpFinancialDetails] = useState(
    JSON.parse(data.SignUpFinancialDetails) || ''
  );
  const [income, setIncome] = useState('');
  const [accountPurpose, setAccountPurpose] = useState('');
  const [transactionVolume, setTransactionVolume] = useState('');
  const [occupationStatus, setOccupationStatus] = useState('');

  useEffect(() => {
    setCountry(data.country);
    setCity(data.city);
    setAddress(data.address);
    setDob(data.birthdate);
    setPostalCode(data.postalCode);
    setPhoneCode(data.phoneCode);
    setPhoneNo(data.phoneNumber);
    setEmail(data.email);
    setIncome(signUpFinancialDetails.income);
    setAccountPurpose(signUpFinancialDetails.accountPurpose);
    setTransactionVolume(signUpFinancialDetails.transactionVolume);
    setOccupationStatus(signUpFinancialDetails.occupationStatus);
  }, [data]);

  const save = async event => {
    event.preventDefault();
    try {
      const mutation = 'updateUserProfile';
      const input = {
        id: data.id,
        country,
        city,
        email,
        address,
        postalCode,
        phoneCode,
        birthdate: dob,
        phoneNumber,
        SignUpFinancialDetails: JSON.stringify({ occupation, income, occupationStatus }),
      };
      const result = await graphqlApiDecorator(mutations, mutation, { input });
      if (result) {
        setHeader('Saved');
        setContent('User details saved');
        setShowSavedModal(true);
      }
    } catch (error) {
      setMessage(error.message);
      setShowWarningModal(true);
    }
  };

  const confirmedResetPw = async () => {
    setShowWarningModal(false);
    const result = await resetUserPassword(data.id);
    if (result) {
      setHeader('Code sent');
      setContent('Password code sent to user');
      setShowSavedModal(true);
    }
  };

  return (
    <div>
      <div className={classes.label}>
        <aside>
          <SideRectangle />
          <header>Info</header>
        </aside>
        <button type="submit" onClick={save}>
          <SmallSave />
        </button>
      </div>
      <main className={classes.infoContainer}>
        <section>
          <InfoInput
            id="Country"
            type="text"
            value={country}
            onChange={event => setCountry(event.target.value)}
          />
          <InfoInput
            id="City"
            type="text"
            value={city}
            onChange={event => setCity(event.target.value)}
          />
        </section>
        <section>
          <InfoInput
            id="Address"
            type="text"
            value={address}
            onChange={event => setAddress(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Date of Birth"
            type="date"
            value={dob}
            onChange={event => setDob(event.target.value)}
          />
          <InfoInput
            id="Postal Code"
            type="text"
            value={postalCode}
            onChange={event => setPostalCode(event.target.value)}
          />
        </section>
        <section>
          <InfoInput
            id="Phone Code"
            type="text"
            value={phoneCode}
            onChange={event => setPhoneCode(event.target.value)}
          />
          <InfoInput
            id="Phone Number"
            type="text"
            value={phoneNumber}
            onChange={event => setPhoneNo(event.target.value)}
          />
        </section>
        <section>
          <InfoInput
            id="Email"
            type="text"
            value={email}
            onChange={event => setEmail(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Account Purpose"
            type="text"
            value={accountPurpose}
            onChange={event => setAccountPurpose(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Average Monthly Transaction Volume"
            type="text"
            value={transactionVolume}
            onChange={event => setTransactionVolume(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Average Income"
            type="text"
            value={income}
            onChange={event => setIncome(event.target.value)}
            fullWidth
          />
        </section>
        <section>
          <InfoInput
            id="Occupation Status"
            type="text"
            value={occupationStatus}
            onChange={event => setOccupationStatus(event.target.value)}
            fullWidth
          />
        </section>
      </main>
      <SavedModal
        open={showSavedModal}
        onClose={() => setShowSavedModal(false)}
        header={header}
        content={content}
      />
      <WarningModal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        confirm={confirmedResetPw}
        message={message}
      />
    </div>
  );
};
