const toStringOrEmptyString = str => (str ? `${str}` : '');

const toStringOrDash = str => (str ? `${str}` : '-');

const capitalizeString = str => (str ? `${str.slice(0, 1).toUpperCase()}${str.slice(1)}` : '');

const lowerCaseString = str => (str ? `${str.slice(0, 1).toLowerCase()}${str.slice(1)}` : '');

const splitString = ({ str, separator = '_' }) => (str ? str.split(separator).join(' ') : '');

const splitAndCapitalizeString = ({ str, separator = '_' }) =>
  str ? str.split(separator).map(capitalizeString).join(' ') : '';

const connectString = str => str.replace(/\s/g, '').toLowerCase();

// Returns an email in form of s****@kixy.com
const formatEmailPrivate = str => {
  const strArr = str.split('');

  const formattedEmail = [];

  let letterFlag = false;
  strArr.forEach((char, index) => {
    if (index === 0) {
      formattedEmail.push(char);

      return;
    }
    if (!letterFlag && char !== '@') {
      formattedEmail.push('*');

      return;
    }
    if (!letterFlag && char === '@') {
      formattedEmail.push(char);
      letterFlag = true;

      return;
    }

    if (letterFlag) formattedEmail.push(char);
  });

  return formattedEmail.join('');
};

const formatDOBString = str => {
  // str format is dd/mm/yyyy, we want it to be yyyy-mm-dd
  if (str.length !== 10) return str;
  const strArray = str.split('/');
  if (strArray.length !== 3) return str;
  const newStrArray = [...strArray];
  newStrArray.reverse();

  return newStrArray.join('-');
};
function numberWithCommas(x) {
  return (x = x + '').replace(
    new RegExp('\\B(?=(\\d{3})+' + (~x.indexOf('.') ? '\\.' : '$') + ')', 'g'),
    ','
  );
}
function getAvatarInitials(name, lastName) {
  if (!name || !lastName) {
    return '';
  }

  const firstInitial = name.trim().charAt(0).toUpperCase();
  const lastInitial = lastName.trim().charAt(0).toUpperCase();

  return `${firstInitial}${lastInitial}`;
}

function convertStringFormatWithoutDash(input) {
  return input
    .replace(/_/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^\w|\s\w/g, match => match.toUpperCase());
}

export {
  capitalizeString,
  connectString,
  formatDOBString,
  formatEmailPrivate,
  lowerCaseString,
  splitAndCapitalizeString,
  splitString,
  toStringOrDash,
  toStringOrEmptyString,
  numberWithCommas,
  getAvatarInitials,
  convertStringFormatWithoutDash,
};
