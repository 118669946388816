import { makeStyles } from '@mui/styles';

import deleteModalBckgrnd from '../icons/alertPopupBckgrnd.svg';

export const useStyles = makeStyles(theme => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '458px',
    minHeight: '279px',
    height: 'auto',
    borderRadius: '15px',
    backgroundImage: `url(${deleteModalBckgrnd})`,
    backgroundRepeat: 'none',
    border: 'none',
    boxShadow: 24,
    fontSize: '18px',
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    '& > header': {
      marginTop: '44px',
      textAlign: 'center',
      fontWeight: '500',
    },
    '& > p': {
      textAlign: 'center',
      marginTop: '22px',
      padding: '0px 5px',
    },
    '& .rowContainer': {
      marginBottom: '20px',
    },
  },
  iconContainer: {
    position: 'relative',
    top: '18px',
    left: '196.5px',
  },
  cancelDelSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '145px',
    alignItems: 'center',
    marginTop: '32px',
    fontWeight: '500',
    '& div': {
      cursor: 'pointer',
    },
  },
  cancel: {
    color: '#0069f8',
  },
  deleteIt: {
    color: '#fc3333',
  },
}));
