import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Card, CardContent, Table, TableBody, TableHead } from '@mui/material';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { entitiesEnum } from '../../constants';
import { removeEntity, saveEntity } from '../../services';
import {
  getElementTop,
  getFilteredTableFields,
  isEntityCanBeEdited,
  isEntityCanBeRemoved,
  setElementHeight,
} from '../../utils';
import { Loader } from '../loader';
import { CreateAdminModal, EditAdminModal } from '../modal';
import { ActionModal, CustomersTableHead, GenericTableBody, GenericTableHead } from './components';
import { CurrenciesTable } from './components/currencies-table/currencies-table';
import {
  CustomerFilterSummary,
  TransactionFilterSummary,
} from './components/generic-table-body/components';
import { CountriesTableBody } from './components/generic-table-body/countries-table-body';
import { CustomersTable } from './customers-table/customers-table';
import { useStyles } from './generic-table.hook';

/**
 * Checks if we have pagination for the given entity.
 */
const handleIsPaginationEnabled = entity => {
  const isTransactionEntity = entity === entitiesEnum.TRANSACTION;
  const isCustomersEntity = entity === entitiesEnum.USERPROFILE;
  return isTransactionEntity || isCustomersEntity;
};

export const GenericTable = ({
  showCreateAdminModal,
  setShowCreateAdminModal,
  data,
  entity,
  error,
  loading,
  onRefreshData,
  tableFields,
  onRemoveItem,
  canOrder,
  page,
  setFilters,
  filters,
  limit,
  searchTransactions,
  isSearchMode,
  hasNextPageTransactionSearchByName,
  noOfPagesTransactionSearchResults,
  transactionSearchResultsPage,
  setTransactionSearchResultsPage,
  customerSearchResultsPage,
  hasNextPageCustomerSearch,
  noOfPagesCustomerSearchResults,
  setCustomerSearchResultsPage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transactionFilterObj = useSelector(state => state.transactionFilterObj);

  const navigate = useNavigate();
  const location = useLocation();
  const tableRef = useRef();

  const [removingItem, setRemovingItem] = useState({});
  const [showActionModal, setShowActionModal] = useState(false);
  const [showEditAdminModal, setShowEditAdminModal] = useState(false);
  const [adminToEditData, setAdminToEditData] = useState({});
  const [editingIndex, setEditingIndex] = useState('');
  const [actionKind, setActionKind] = useState('');
  const [disablingAccount, setDisablingAccount] = useState({});

  const [tableTop, setTableTop] = useState(0);
  const [isAllCustomerRecordsChecked, setIsAllCustomerRecordsChecked] = useState(false);
  const [customerCheckedRecords, setCustomerCheckedRecords] = useState([]);

  const isPaginationEnabled = handleIsPaginationEnabled(entity);
  const isTransaction = entity === entitiesEnum.TRANSACTION;
  const isCustomers = entity === entitiesEnum.USERPROFILE;
  const isCurrencies = entity === entitiesEnum.CURRENCY;
  const isCountries = entity === entitiesEnum.COUNTRY;

  const isEdited = isEntityCanBeEdited(entity);
  const isRemoved = isEntityCanBeRemoved(entity);

  const [currentPageCustomer, setCurrentPageCustomer] = useState(0);
  const [currentPageTransactions, setCurrentPageTransactions] = useState(0);

  const getAccountFromURL = () => {
    const params = new URLSearchParams(location.search);

    return params.get('account') || 'all';
  };
  const [accountType, setAccountType] = useState(getAccountFromURL());

  const handleAccountChange = newAccount => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('account', newAccount);
    navigate(`?${searchParams.toString()}`, { replace: false });
  };

  const getPageFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = parseInt(searchParams.get('page') || '1', 10);
    return isNaN(pageParam) ? 0 : pageParam - 1;
  };

  useEffect(() => {
    if (!isSearchMode) dispatch({ type: 'SET_SEARCH_TRANSACTION_SEARCH_QUERY', payload: '' });
    dispatch({ type: 'SET_USER_TRANSACTION_SEARCH_QUERY', payload: '' });
    dispatch({ type: 'SET_SEARCH_CURRENCY_QUERY', payload: '' });
    dispatch({ type: 'SET_TRANSACTION_SORT', payload: '' });
    dispatch({ type: 'SET_USER_TRANSACTION_SORT', payload: '' });
    dispatch({ type: 'SET_CUSTOMER_SORT', payload: '' });
  }, [entity, dispatch, isSearchMode]);

  useEffect(() => {
    const newPageIndex = getPageFromURL();
    if (isCustomers) {
      setCurrentPageCustomer(newPageIndex);
      setCustomerSearchResultsPage(newPageIndex);
    } else if (isTransaction) {
      setCurrentPageTransactions(newPageIndex);
      setTransactionSearchResultsPage(newPageIndex);
    }

    handlePaginationPageClick(null);
  }, [
    location.search,
    isCustomers,
    isTransaction,
    setCustomerSearchResultsPage,
    setTransactionSearchResultsPage,
  ]);

  useEffect(() => {
    const genericTableTop = getElementTop(tableRef);
    setTableTop(genericTableTop);
  }, [entity]);

  useEffect(() => {
    if (!isTransaction && !isCustomers) {
      setCurrentPageTransactions(0);
      setCurrentPageCustomer(0);
    }
  }, [entity, isTransaction, isCustomers]);

  const handlePaginationPageClick = event => {
    const params = new URLSearchParams(location.search);

    const newPageIndex = getPageFromURL();
    const selected = event?.selected ?? newPageIndex;
    if (isTransaction) {
      navigate(`/transaction?page=${selected + 1}`);
    } else if (isCustomers) {
      navigate(`/userprofile?page=${selected + 1}&account=${params.get('account') || 'all'}`);
    }
  };

  let wrapperPadding = 16;
  if (isCustomers) wrapperPadding = 40;
  if (entity === entitiesEnum.TRANSACTION) wrapperPadding = 24;
  const paginationHeight = 67;

  let indent = tableTop + wrapperPadding;
  if (isPaginationEnabled) {
    indent += paginationHeight;
  }

  const handleRemoveEntity = (id, index) => {
    setActionKind('remove');
    setRemovingItem({ id, index });
    setShowActionModal(true);
  };

  const handleDisableAccount = (active, id) => {
    setActionKind('disable');
    setDisablingAccount({ active, id });
    setShowActionModal(true);
  };

  const handleConfirmDisable = async () => {
    const { active, id } = disablingAccount;
    await saveEntity({ entity, values: { active: !active, id }, id });
    setShowActionModal(false);
    onRefreshData();
  };

  const handleCloseModal = () => {
    setShowActionModal(false);
  };

  const handleConfirmRemove = async () => {
    const { id, index } = removingItem;
    if (onRemoveItem) {
      onRemoveItem(index);
      handleCloseModal();
      return;
    }
    await removeEntity(entity, id);
    setShowActionModal(false);
    onRefreshData();
  };

  const handleCloseCreateAdminModal = () => {
    setShowCreateAdminModal(false);
  };

  const handleEditAdmin = (adminData, index) => {
    setShowEditAdminModal(true);
    setAdminToEditData(adminData);
    setEditingIndex(index);
  };

  const handleCloseEditAdminModal = () => {
    setShowEditAdminModal(false);
  };

  const onClickedTransactionRow = rowData => {
    navigate(`/${entity.toLowerCase()}/${rowData.id}`);
  };

  const previousBtnClassName = transactionSearchResultsPage
    ? clsx(classes.activeLink, classes.btnLink)
    : clsx(classes.inactiveLink, classes.btnLink);

  const prevBtnClassNameCustomer = customerSearchResultsPage
    ? clsx(classes.activeLink, classes.btnLink)
    : clsx(classes.inactiveLink, classes.btnLink);

  const nextBtnClassName = hasNextPageTransactionSearchByName
    ? clsx(classes.activeLink, classes.btnLink)
    : clsx(classes.inactiveLink, classes.btnLink);

  const nextBtnClassNameCustomer = hasNextPageCustomerSearch
    ? clsx(classes.activeLink, classes.btnLink)
    : clsx(classes.inactiveLink, classes.btnLink);

  const previousPage = () => {
    let disabled = false;
    if (isCustomers) disabled = customerSearchResultsPage === 0;
    if (isTransaction) disabled = transactionSearchResultsPage === 0;

    return (
      <Button
        type="submit"
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          if (isTransaction) {
            navigate(`/transaction?page=${transactionSearchResultsPage}`);
          } else if (isCustomers) {
            navigate(`/userprofile?page=${customerSearchResultsPage}&account=${accountType}`);
          }
        }}
        className={isTransaction ? previousBtnClassName : prevBtnClassNameCustomer}
      >
        <ArrowBackIcon sx={{ height: '24px', width: '24px' }} color={previousBtnClassName} />
      </Button>
    );
  };

  const nextPage = () => {
    let disabled = false;
    if (isCustomers) disabled = !hasNextPageCustomerSearch;
    if (isTransaction) disabled = !hasNextPageTransactionSearchByName;

    return (
      <Button
        type="submit"
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          if (isTransaction) {
            navigate(`/transaction?page=${transactionSearchResultsPage + 2}`);
          } else if (isCustomers) {
            navigate(`/userprofile?page=${customerSearchResultsPage + 2}&account=${accountType}`);
          }
        }}
        className={isTransaction ? nextBtnClassName : nextBtnClassNameCustomer}
      >
        <ArrowForwardIcon
          sx={{ height: '24px', width: '24px' }}
          color={isTransaction ? nextBtnClassName : nextBtnClassNameCustomer}
        />
      </Button>
    );
  };

  const renderGenericTableHead = () => {
    const genericTableHeadProps = {
      entity,
      filters,
      setFilters,
      isEntityCanBeEdited: isEdited,
      isEntityCanBeRemoved: isRemoved,
      tableFields: getFilteredTableFields(tableFields, entity),
    };

    if (isTransaction) return null;
    if (isCustomers) {
      return (
        <CustomersTableHead
          isAllRecordsChecked={isAllCustomerRecordsChecked}
          setIsAllCustomerRecordsChecked={setIsAllCustomerRecordsChecked}
        />
      );
    }
    if (isCurrencies) {
      return null;
    }
    return <GenericTableHead {...genericTableHeadProps} />;
  };

  const renderGenericTableBody = () => {
    const genericTableBodyProps = {
      loading,
      error,
      data,
      canOrder,
      entity,
      tableFields: getFilteredTableFields(tableFields, entity),
      onClickedTransactionRow,
      onDisableAccount: handleDisableAccount,
      onEditAdmin: handleEditAdmin,
      onRemoveEntity: handleRemoveEntity,
      page,
      limit,
      searchTransactions,
      isSearchMode,
    };

    if (isCustomers) {
      return (
        <CustomersTable
          data={data}
          entity={entity}
          loading={loading}
          isAllRecordsChecked={isAllCustomerRecordsChecked}
          setIsAllCustomerRecordsChecked={setIsAllCustomerRecordsChecked}
          checkedRecords={customerCheckedRecords}
          setCheckedRecords={setCustomerCheckedRecords}
        />
      );
    }
    if (isCurrencies) {
      return <CurrenciesTable {...genericTableBodyProps} />;
    }
    if (isCountries) {
      return (
        <CountriesTableBody
          data={data}
          tableColumns={getFilteredTableFields(tableFields, entity)}
        />
      );
    }
    return <GenericTableBody {...genericTableBodyProps} />;
  };

  return (
    <>
      {isTransaction && <TransactionFilterSummary isSearchMode={isSearchMode} />}
      {isCustomers && <CustomerFilterSummary accountType={accountType} />}

      <Card
        className={clsx(
          classes.root,
          isTransaction && classes.backgroundTransaction,
          isCustomers && classes.backgroundCustomers,
          isCurrencies && classes.backgroundCurrencies
        )}
        id="teamlist"
        ref={tableRef}
        style={{
          height: setElementHeight(indent),
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <CardContent
            className={
              isTransaction
                ? `${classes.content} ${classes.backgroundTransaction}`
                : classes.content
            }
          >
            <div className={classes.inner}>
              <Table
                className={
                  isCountries || isCustomers ? classes.tableRootCountryEntity : classes.tableRoot
                }
              >
                <TableHead>{renderGenericTableHead()}</TableHead>
                <TableBody>{renderGenericTableBody()}</TableBody>
              </Table>
            </div>
          </CardContent>
        )}

        <ActionModal
          onClose={handleCloseModal}
          onShow={showActionModal}
          actionKind={actionKind}
          confirmRemove={handleConfirmRemove}
          confirmDisable={handleConfirmDisable}
          classes={classes}
          disablingAccount={disablingAccount}
        />

        <CreateAdminModal
          onClose={handleCloseCreateAdminModal}
          onShow={showCreateAdminModal}
          onCreate={saveEntity}
          onRefreshData={onRefreshData}
          entity={entity}
          styles={classes}
        />

        <EditAdminModal
          onClose={handleCloseEditAdminModal}
          onShow={showEditAdminModal}
          onSave={saveEntity}
          onDelete={handleRemoveEntity}
          entity={entity}
          styles={classes}
          data={adminToEditData}
          index={editingIndex}
          onRefreshData={onRefreshData}
        />
      </Card>

      {isCustomers && noOfPagesCustomerSearchResults && (
        <div className={classes.paginationContainer}>
          <ReactPaginate
            className={classes.pageNumbers}
            activeClassName={classes.activePage}
            breakLabel="..."
            nextLabel={nextPage()}
            onPageChange={handlePaginationPageClick}
            pageRangeDisplayed={10}
            pageCount={noOfPagesCustomerSearchResults}
            previousLabel={previousPage()}
            renderOnZeroPageCount={null}
            forcePage={currentPageCustomer}
          />
        </div>
      )}

      {isTransaction &&
        noOfPagesTransactionSearchResults &&
        Object.keys(transactionFilterObj).length === 0 && (
          <div className={classes.paginationContainer}>
            <ReactPaginate
              className={classes.pageNumbers}
              activeClassName={classes.activePage}
              breakLabel="..."
              nextLabel={nextPage()}
              onPageChange={handlePaginationPageClick}
              pageRangeDisplayed={10}
              pageCount={noOfPagesTransactionSearchResults}
              previousLabel={previousPage()}
              renderOnZeroPageCount={null}
              forcePage={currentPageTransactions}
            />
          </div>
        )}
    </>
  );
};
