import React, { useEffect, useState } from 'react';
import { useStyles } from './customer-filter-modal-by-type.hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const CustomerFilterModalByType = ({ listData }) => {
  const [dataObj, setDataObj] = useState(sessionStorage.getItem('account') || 'all');
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchCustomerDataFromQuery } = listData;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accountType = searchParams.get('account') || 'all';
    if (accountType) {
      handleSubmit(accountType);
    }
  }, []);

  const getCurrentURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const pathName = location.pathname;
    const currentPage = searchParams.get('page') || '1';

    return {
      currentPage,
      pathName,
    };
  };

  const handleToggle = type => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('page', '1');
    searchParams.set('account', type);

    localStorage.setItem('account', type);

    const { pathName, currentPage } = getCurrentURL();

    navigate(`${pathName}?${searchParams.toString()}`);

    setDataObj(type);
    handleSubmit(type);
  };

  const handleSubmit = async input => {
    setDataObj(input);
    fetchCustomerDataFromQuery({
      query: '',
      key: 'name',
      account: input === 'all' ? '' : input,
    });
    sessionStorage.setItem('account', input);
    localStorage.setItem('account', input);
  };

  return (
    <div className={classes.customerFilterModalContainer}>
      <div>
        <div className={classes.actionBtnContainer}>
          <div className={classes.statusRow}>
            <div
              className={
                dataObj === 'all'
                  ? `${classes.createdActive} ${classes.defaultStatus}`
                  : classes.defaultStatus
              }
              onClick={() => handleToggle('all')}
            >
              All
            </div>
            <div
              className={
                dataObj === 'personal'
                  ? `${classes.createdActive} ${classes.defaultStatus}`
                  : classes.defaultStatus
              }
              onClick={() => handleToggle('personal')}
            >
              Personal
            </div>
            <div
              className={
                dataObj === 'company'
                  ? `${classes.createdActive} ${classes.defaultStatus}`
                  : classes.defaultStatus
              }
              onClick={() => handleToggle('company')}
            >
              Business
            </div>
            <div
              className={
                dataObj === 'freelance'
                  ? `${classes.createdActive} ${classes.defaultStatus}`
                  : classes.defaultStatus
              }
              onClick={() => handleToggle('freelance')}
            >
              Freelancer
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
