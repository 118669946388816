import BuildIcon from '@mui/icons-material/Build';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import TextsmsIcon from '@mui/icons-material/Textsms';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import { Icon } from '@mui/material';
import React from 'react';

import {
  AdminTeamIcon,
  CountriesIcon,
  CurrenciesIcon,
  MulticurrencyIcon,
  CustomersIcon,
  Metrics,
  PairsIcon,
  TransactionsIcon,
  KybPannel,
  CampaignPanel,
} from '../../../../components/icons';
import { palette } from '../../../../theme/palette';
import { getCurrentUser } from 'services';

const pagesHidden = [
  {
    title: 'Onboarding',
    href: '/onboarding',
    icon: <TextsmsIcon />,
  },
  {
    title: 'Memberships',
    href: '/membership',
    icon: <WidgetsRoundedIcon />,
  },
  {
    title: 'Notifications',
    href: '/notification',
    icon: <NotificationsNoneIcon />,
  },
  {
    title: 'Discounts',
    href: '/discount',
    icon: <LoyaltyIcon />,
  },
  {
    title: 'Geo Regions',
    href: '/region',
    icon: <PublicIcon />,
  },
  {
    title: 'Configuration',
    href: '/config',
    icon: <SettingsIcon />,
  },
  {
    title: 'Team',
    href: '/team',
    icon: <BuildIcon />,
  },
  {
    title: 'Pairs',
    href: '/pair-new',
    icon: (
      <Icon style={palette.icons.style}>
        <PairsIcon color={palette.icons.sidebar} />
      </Icon>
    ),
  },
];

const initialPages = [
  {
    title: 'Customers',
    href: '/userprofile',
    icon: CustomersIcon,
    roles: ['admin', 'developers', 'compliance', 'support', 'aml', 'operation'],
  },
  {
    title: 'Transactions',
    href: '/transaction',
    icon: TransactionsIcon,
    roles: ['admin', 'developers', 'compliance', 'operation'],
  },
  // {
  //   title: 'Companies',
  //   href: '/table',
  //   icon: Metrics,
  //   roles: ['admin', 'compliance'],
  // },
  {
    title: 'Pairs',
    href: '/pair',
    icon: PairsIcon,
    roles: ['admin', 'developers', 'operation'],
  },
  {
    title: 'Currencies',
    href: '/currency',
    icon: CurrenciesIcon,
    roles: ['admin', 'developers', 'operation'],
  },
  {
    title: 'Countries',
    href: '/country',
    icon: CountriesIcon,
    roles: ['admin', 'developers', 'operation'],
  },
  {
    title: 'Team',
    href: '/adminTeam',
    icon: AdminTeamIcon,
    roles: ['admin', 'operation'],
  },
  {
    title: 'Multicurrency',
    href: '/multicurrency',
    icon: MulticurrencyIcon,
    roles: ['admin', 'mccy'],
  },
  {
    title: 'Metrics',
    href: '/metrics',
    icon: Metrics,
    roles: ['admin', 'developers', 'operation', 'compliance'],
  },
  {
    title: 'Campaign',
    href: '/campaign',
    icon: CampaignPanel,
    roles: ['admin', 'developers', 'operation', 'compliance'],
  },
];

const fetchAndUpdatePages = async () => {
  let pages = [...initialPages];

  try {
    const { accessToken } = await getCurrentUser();

    const response = await fetch(`${process.env.REACT_APP_STATEMENT_URL}/user/admin-menu`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    data.resources.forEach(item => {
      const titleExists = pages.some(page => page.title === item.title);

      if (!titleExists) {
        const newPage = {
          title: item.title,
          href: `/${item.title.toLowerCase()}`,
          icon: KybPannel,
          roles: ['admin', 'compliance', 'operation', 'support', 'aml'],
        };
        pages.push(newPage);
      }
    });
  } catch (error) {
    console.error('Failed to fetch and update pages:', error);
  }

  return pages;
};

export { fetchAndUpdatePages, pagesHidden };
