import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import { Amplify } from '@aws-amplify/core';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import validate from 'validate.js';

import { awsConfig } from './config/aws.config';
import { UserContext } from './contexts/user-role';
import { RoutesList } from './routes';
import { getCurrentUser } from './services';
import { theme } from './theme';
import * as validators from './utils';

Amplify.configure(awsConfig.amplify);

validate.validators = {
  ...validate.validators,
  ...validators,
};

export const App = () => {
  const [userRoles, setUserRoles] = useState(null);
  const [userName, setUserName] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSessionUser = async () => {
      const { success, data: userData } = await getCurrentUser();
      setLoading(false);

      // const roles = userData?.attributes?.['custom:groups'];
      const roles = userData?.group;
      const username = userData?.username ?? '';
      localStorage.setItem('userRoles', roles);

      if (success) {
        if (username) {
          setUserName(username);
        }

        if (roles) {
          // setUserRoles(roles.split(','));
          setUserRoles(roles);
        }
      }
    };
    getSessionUser();
  }, [userName]);

  return (
    <StyledEngineProvider injectFirst>
      {/* <Provider store={store}> */}
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={{ userRoles, setUserRoles, userName, setUserName }}>
          <BrowserRouter>{loading ? null : <RoutesList userRoles={userRoles} />}</BrowserRouter>
        </UserContext.Provider>
      </ThemeProvider>
      {/* </Provider> */}
    </StyledEngineProvider>
  );
};
