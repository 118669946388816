/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';

import { entitiesEnum } from '../../../constants';
import { useGraphGet } from '../../../hooks';
import { getKycInfo, getMappedEntity } from '../../../utils';
import { Loader } from '../../loader';
import { IntercomButton, SumsubButton } from '../../social-buttons';
import { entity } from '../../user-item/user-item.constants';
import { useStyles } from './customer-detail-page.hook';
import {
  Account,
  BalanceHistory,
  Beneficiaries,
  Info,
  KycKyb,
  Notes,
  Notifications,
  Transactions,
  Wallets,
} from './detail-components';
import { LimitationFilterModal } from './limitation-filter-modal';
import { CompanyHouseButton } from 'components/social-buttons/CompanyHouseButton';
import { getCurrentUser } from 'services';
import { getCompanyHouseInfo } from 'utils/companyHouse.utils';
import { graphqlApiDecorator } from 'decorators';
import { queries } from '../../../graphql';
import { SendOtp } from './otp-modal';
/** This page is a replacement for the UserItem.jsx component. The page is displayed when a user clicks on a
 * customer row in the CustomersTable component in order to get more details. The customer details page is composed
 * of many sub-components: Account, Wallets, Info, Notes, Notifications, KycKyb, ActivityLogs, Beneficiaries, Transactions
 * */
export const CustomerDetailsPage = () => {
  const [idChanged, setIdChanged] = useState(false);
  const [userId, setUserId] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  const { fields } = useMemo(() => getMappedEntity(entity), [entity]);
  const classes = useStyles();
  const [openCustomerFilterModal, setOpenCustomerFilterModal] = useState(false);
  const [closeAccount, setCloseAccount] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);

  const { loading, rawData, refetch } = useGraphGet(entity, id, fields);

  const { applicantId, kycLink } = getKycInfo(rawData?.kyc);

  const businessDetails = rawData.businessDetail;
  if (businessDetails) {
    const newBusinessDetails = JSON.parse(businessDetails);
    var registrationNumber = newBusinessDetails.registrationNumber;
  }
  const { companyLink } = getCompanyHouseInfo(registrationNumber);

  const [userRoles, setUserRoles] = useState(null);

  const navigateUserProfile = userId => {
    navigate(`/${entitiesEnum.USERPROFILE.toLowerCase()}/${userId}`);
    // the navigate() changes the URL but page does not change so need to reload

    window.location.reload();
  };

  useEffect(() => {
    if (!idChanged) {
      setIdChanged(true);
    }

    if (idChanged) window.location.reload();

    const getSessionUser = async () => {
      const { success, data: userData } = await getCurrentUser();

      if (success) {
        setUserRoles(userData?.group);
      }
    };
    getSessionUser();
  }, [id]);

  useEffect(() => {
    refetch();
  }, [closeAccount]);

  useEffect(() => {
    fetchLimitation(id);
    if (Object.keys(rawData).length)
      document.title = `Customer Details - ${rawData.name} ${rawData.lastName}`;
  }, [rawData]);

  const handleOpenCustomerFilterModal = () => setOpenCustomerFilterModal(true);
  const handleOpenOtpModal = () => setOpenOtpModal(true);

  const fetchLimitation = async id => {
    const queryTitle = 'getUserBeneficiaries';
    try {
      const result = await graphqlApiDecorator(queries, queryTitle, { id });
      if (result !== null) {
        setBeneficiaries(result.items);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      {loading || closeAccount ? (
        <Loader />
      ) : (
        <>
          <section className={classes.toolbar}>
            <header>{`${rawData.name} ${rawData.lastName || ''}`}</header>
            <aside>
              <p>View on:</p>
              <IntercomButton
                profileUrl={rawData?.intercomProfileUrl}
                accountStatus={rawData?.accountStatus}
              />
              <SumsubButton profileUrl={kycLink} disabled={!applicantId} />
              {rawData.accountType === 'company' && <CompanyHouseButton profileUrl={companyLink} />}
              {['admin', 'compliance'].some(role => userRoles?.includes(role)) && (
                <div className="limitationBorder">
                  <Button
                    sx={{
                      width: '100%',
                      height: '32px',
                      border: '1px solid #2BE4B0',
                      color: '#093560',
                      marginLeft: '20px',
                      borderRadius: '16px',
                      textTransform: 'none',
                    }}
                    onClick={handleOpenCustomerFilterModal}
                  >
                    <p>Transaction Limits</p>
                  </Button>
                </div>
              )}

              {['admin', 'compliance', 'support', 'aml'].some(role =>
                userRoles?.includes(role)
              ) && (
                <div className="otpButton">
                  <Button
                    sx={{
                      width: '100%',
                      height: '32px',
                      border: '1px solid #2BE4B0',
                      color: '#093560',
                      marginLeft: '20px',
                      borderRadius: '16px',
                      textTransform: 'none',
                    }}
                    onClick={handleOpenOtpModal}
                  >
                    <p>OTP</p>
                  </Button>
                </div>
              )}
            </aside>
          </section>
          <div className={classes.inlineBlock}>
            <main className={classes.gridContainer}>
              <div className={clsx(classes.gridItem, classes.accountContainer)}>
                <Account
                  data={rawData}
                  id={id}
                  roles={userRoles}
                  closedAccount={input => {
                    setCloseAccount(input);
                  }}
                />
              </div>
              <div className={clsx(classes.gridItem, classes.walletsContainer)}>
                <Wallets data={rawData} setId={input => setUserId(input)} />
              </div>
              <div className={clsx(classes.gridItem, classes.infoContainer)}>
                <Info data={rawData} />
              </div>
              <div className={clsx(classes.gridItem, classes.noteContainer)}>
                <Notes data={rawData} />
              </div>
              <div className={clsx(classes.gridItem, classes.notificationsContainer)}>
                <Notifications data={rawData} />
              </div>
              <div className={classes.gridItem}>
                <KycKyb data={rawData} />
              </div>
              <div className={clsx(classes.gridItem, classes.activityLogsContainer)}>
                <BalanceHistory data={rawData} />
              </div>
              <div className={clsx(classes.gridItem, classes.beneficiaryContainer)}>
                <Beneficiaries
                  data={rawData}
                  beneficiaries={beneficiaries}
                  navigateUserProfile={navigateUserProfile}
                />
              </div>
              <div className={clsx(classes.gridItem, classes.transactionsContainer)}>
                <Transactions data={rawData} entity={entitiesEnum.USERPROFILE} />
              </div>
            </main>
          </div>
          <Modal open={openCustomerFilterModal} onClose={() => setOpenCustomerFilterModal(false)}>
            <Box>
              <LimitationFilterModal
                onClose={() => setOpenCustomerFilterModal(false)}
                data={rawData}
              />
            </Box>
          </Modal>
          <Modal open={openOtpModal} onClose={() => setOpenOtpModal(false)}>
            <Box>
              <SendOtp
                onClose={() => setOpenOtpModal(false)}
                data={rawData}
                refetchProfileData={() => refetch()}
                accountType={rawData.accountType}
              />
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};
