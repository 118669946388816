import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  riskModalContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxHeight: '500px',
    height: 'auto',
    overflow: 'scroll',
    borderRadius: '15px',
    zIndex: 5,
    fontFamily: theme.typography.default,
    color: theme.palette.icons.nav,
    padding: '21px',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
  },

  tableRow: {
    background: theme.palette.white,
    paddingBottom: '19px',
    borderBottom: '1px solid #aeabab',
    color: theme.palette.icons.nav,
    whiteSpace: 'nowrap',
    padding: '1.2rem',
    fontSize: '16px',
    position: 'sticky',
    top: '0',
    zIndex: 3,
  },
  tableCell: {
    backgroundColor: 'white',
    fontSize: '14px',
    color: theme.palette.icons.nav,
  },
  nameTableCell: {
    fontSize: '14px',
    color: theme.palette.icons.nav,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    paddingLeft: '40px',
    '& input[type="checkbox"]': {
      width: '24px',
      height: '24px',
      borderRadius: '16px',
      border: '1px solid #e6e6e6',
      cursor: 'pointer',
    },
  },
  riskRateBtnContainer: {
    margin: '0 23px 20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  riskRateBtn: {
    background: 'white',
    border: '1px solid #1ce2a9',
    borderRadius: '50px',
    padding: '5px 10px',
    cursor: 'pointer',
    fontSize: '12px',
    transition: 'all 0.25s ease-out',
    '&:active': {
      background: '#1ce2a9',
      opacity: '0.6',
    },
  },
  currentRiskRate: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  seeDetailsBtn: {
    cursor: 'pointer',
    color: '#1ce2a9',
  },
  buttonGrop: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
}));
