import BuildIcon from '@mui/icons-material/Build';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import TextsmsIcon from '@mui/icons-material/Textsms';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import { Icon } from '@mui/material';
import React from 'react';

import AddOnboardingIcon from '../../../assets/icons/add-onboarding.svg';
import {
  CountriesIcon,
  CurrenciesIcon,
  CustomersIcon,
  PairsIcon,
  TransactionsIcon,
  Metrics,
  CampaignPanel,
} from '../../../components/icons';
import { entitiesEnum, entitiesTitles } from '../../../constants';
import { palette } from '../../../theme/palette';
import { getCurrentUser } from 'services';

const getPagesProperties = async () => {
  let pagesProperties = [
    {
      title: 'Customers',
      entity: entitiesEnum.USERPROFILE,
      icon: <CustomersIcon />,
    },
    {
      title: 'Membership',
      entity: entitiesEnum.MEMBERSHIP,
      icon: <WidgetsRoundedIcon />,
      buttons: [
        {
          text: 'New',
          icon: (
            <Icon style={{ display: 'flex', width: 'fit-content', height: '2.4rem' }}>
              <img alt="add" src={AddOnboardingIcon} />
            </Icon>
          ),
          type: 'create',
        },
      ],
    },
    {
      title: 'Notifications',
      entity: entitiesEnum.NOTIFICATION,
      icon: <NotificationsNoneIcon />,
      buttons: [
        {
          text: 'New',
          icon: (
            <Icon style={{ display: 'flex', width: 'fit-content', height: '2.4rem' }}>
              <img alt="add" src={AddOnboardingIcon} />
            </Icon>
          ),
          type: 'create',
        },
      ],
    },
    {
      title: 'Transactions',
      entity: entitiesEnum.TRANSACTION,
      icon: <TransactionsIcon />,
    },
    {
      title: 'Onboarding',
      entity: entitiesEnum.ONBOARDING,
      icon: <TextsmsIcon />,
      buttons: [
        {
          text: 'New',
          icon: (
            <Icon style={{ display: 'flex', width: 'fit-content', height: '2.4rem' }}>
              <img alt="add" src={AddOnboardingIcon} />
            </Icon>
          ),
          type: 'create',
        },
      ],
    },
    {
      title: 'Pairs',
      entity: entitiesEnum.PAIR,
      icon: <PairsIcon />,
    },
    {
      title: 'KYB',
      entity: entitiesEnum.KYB,
      icon: <PairsIcon />,
    },
    {
      title: 'Company Details',
      entity: entitiesEnum.FORM,
      icon: <PairsIcon />,
    },
    {
      title: 'Pairs',
      entity: 'PairsNew',
      icon: (
        <Icon style={{ display: 'flex', width: 'fit-content', height: 'fit-content' }}>
          <PairsIcon color={palette.icons.nav} />
        </Icon>
      ),
    },
    {
      title: 'Discounts',
      entity: entitiesEnum.DISCOUNT,
      icon: <LoyaltyIcon />,
      buttons: [
        {
          text: 'New',
          icon: (
            <Icon style={{ display: 'flex', width: 'fit-content', height: '2.4rem' }}>
              <img alt="add" src={AddOnboardingIcon} />
            </Icon>
          ),
          type: 'create',
        },
      ],
    },
    {
      title: 'Currencies',
      entity: entitiesEnum.CURRENCY,
      icon: <CurrenciesIcon />,
    },
    {
      title: 'Multicurrency',
      entity: entitiesEnum.MULTICURRENCY,
      icon: <CurrenciesIcon />,
    },
    {
      title: 'Countries',
      entity: entitiesEnum.COUNTRY,
      icon: <CountriesIcon />,
    },
    {
      title: 'Geo Regions',
      entity: entitiesEnum.REGION,
      icon: <PublicIcon />,
      buttons: [
        {
          text: 'New',
          icon: (
            <Icon style={{ display: 'flex', width: 'fit-content', height: '2.4rem' }}>
              <img alt="add" src={AddOnboardingIcon} />
            </Icon>
          ),
          type: 'create',
        },
      ],
    },
    {
      title: 'Configuration',
      entity: entitiesEnum.CONFIG,
      icon: <SettingsIcon />,
      buttons: [
        {
          text: 'New',
          icon: (
            <Icon style={{ display: 'flex', width: 'fit-content', height: '2.4rem' }}>
              <img alt="add" src={AddOnboardingIcon} />
            </Icon>
          ),
          type: 'create',
        },
      ],
    },
    {
      title: 'Team',
      entity: entitiesEnum.TEAM,
      menu: ['Team list', 'Activity'],
      icon: <BuildIcon />,
      buttons: [
        {
          text: 'New admin',
          icon: <PersonAddIcon style={{ width: '1.5rem', height: '2.4rem' }} />,
          type: 'create',
        },
      ],
    },
    {
      title: 'Metrics Dashboard',
      entity: entitiesEnum.METRIC,
      icon: <BuildIcon />,
    },
    {
      title: 'Companies',
      entity: entitiesEnum.TABLE,
      icon: <PairsIcon />,
    },
    {
      title: 'Campaign',
      entity: entitiesEnum.CAMPAIGN,
      icon: <CampaignPanel />,
    },
  ];

  try {
    const { accessToken } = await getCurrentUser();

    const response = await fetch(`${process.env.REACT_APP_STATEMENT_URL}/user/admin-menu`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    data.resources.forEach(item => {
      const titleExists = pagesProperties.some(page => page.title === item.title);

      if (!titleExists) {
        const newPageProperty = {
          title: item.title,
          entity: item.entity || item.title.toUpperCase(),
          icon: <Metrics />,
        };
        pagesProperties.push(newPageProperty);
      }
    });

    return pagesProperties;
  } catch (error) {
    console.error('Failed to fetch and update pages:', error);
    return pagesProperties;
  }
};

export { getPagesProperties };
