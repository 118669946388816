/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';

import { graphqlApiDecorator } from '../../../../decorators';
import { mutations, queries } from '../../../../graphql';
import { getProfilePictureURL } from '../../../../services';
import { getAvatarInitials, getFormattedDisplayDate } from '../../../../utils';
import { CopyToClipBoardIcon } from '../../../icons';
import { GBP } from '../icons/countries';
import { useStyles } from './account.hooks';
import accountStatusTree from './assets/accountStatus.png';
import {
  AccountStatus,
  BusinessIcon,
  DefaultAvatar,
  DottedDivider,
  EliteIcon,
  InactiveStatus,
  PersonalIcon,
  PremiumIcon,
  SideRectangle,
  StandardIcon,
} from './icons';
import { DeleteModal, SavedModal, WarningModal } from './modals';
import { Box, Button, Modal } from '@mui/material';
import { RiskModal } from './modals/risk-modal';
import GaugeChart from 'components/gauge-chart/gauge-chart';
import { useNavigate } from 'react-router-dom';
import { entitiesEnum } from 'constants';

/**
 * Account component in Customer Details page. Renders the account data for the userProfile selected
 * @param {object} data the user profile data of type userProfile
 * There are some features that are not yet implemented - such as setting the account type or
 * the membership plan, also the location and IP address details of user. These have been commented out
 * or kept in here for potential use.
 * The user can set the account status and click on the save icon to persist changes. All other attributes
 * are read-only
 */
export const Account = ({ data, id, roles, closedAccount }) => {
  const [accountType, setAccountType] = useState(data?.accountType || 'personal');
  const [membershipPlan, setMembershipPlan] = useState(data?.membership || 'standard');
  const [riskRating, setRiskRating] = useState('low');
  const [accountStatus, setAccountStatus] = useState(data?.accountStatus);
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [savedHeader, setSavedHeader] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [openSavedModal, setOpenSavedModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [content, setContent] = useState('');
  const [iban, setIban] = useState('');
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [currentRiskLevel, setCurrentRiskLevel] = useState('');
  const [disableCloseBtn, setDisableCloseBtn] = useState(false);
  const [showWarningModalCloseAccount, setShowWarningModalCloseAccount] = useState(false);
  const [showWarningModalSusspendAccount, setShowWarningModalSusspendAccount] = useState(false);
  const [showLastWarningModalCloseAccount, setShowLastWarningModalCloseAccount] = useState(false);
  const [showSuccessCloseAccountModal, setShowSuccessCloseAccountModal] = useState(false);
  const [showFaileCloseAccountModal, setShowFaileCloseAccountModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [newStatuse, setNewStatuse] = useState('');

  const navigate = useNavigate();

  const normalIconText = { color: '#a5a6ae', fontWeight: 'normal' };
  const boldText = { fontWeight: 'bold' };
  const greyText = { color: '#a5a6ae' };
  const userIBAN = data?.poundsAccount?.iban || '';

  const [openCustomerFilterModal, setOpenCustomerFilterModal] = useState(false);
  const handleOpenCustomerFilterModal = () => setOpenCustomerFilterModal(true);

  const getProfilePic = async () => {
    const profilePictureURL = await getProfilePictureURL(data.id);
    setProfilePicUrl(profilePictureURL);
  };

  const fetchUserWallets = async id => {
    const getUserWallets = 'getUserWallets';
    try {
      const result = await graphqlApiDecorator(queries, getUserWallets, { id });
      if (result !== undefined) {
        result.map(item => {
          if (item.currency === 'GBP') {
            setIban(item.iban);
          }
        });
      }
    } catch (error) {
      setContent(error.message);
    }
  };

  useEffect(() => {
    fetchUserWallets(id);
    fetchCurrentRisk();
  }, [id]);

  useEffect(() => {
    fetchCurrentRisk();
  }, [openCustomerFilterModal]);

  const fetchCurrentRisk = async () => {
    const queryTitle = 'getLatestUserRisks';
    const userId = id;

    try {
      setLoading(true);
      const result = await graphqlApiDecorator(queries, queryTitle, { userId: userId });
      if (result) {
        const currentRiskRate = result.filter(item => item.type === 'OVERALL');

        if (currentRiskRate.length > 0) {
          setCurrentRiskLevel(currentRiskRate[0].riskLevel);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getSortCodeFromIban = iban => {
    if (iban) return iban.slice(8, 14);

    return '';
  };

  const getAccountNoFromIban = iban => {
    if (iban) return iban.slice(14);

    return '';
  };

  const saveUserProfile = async () => {
    const BoUpdateUserProfileInput = {
      id: data.id,
      accountStatus: newStatuse,
      boardStatus:
        newStatuse === 'suspended'
          ? 'Verification'
          : newStatuse === 'active'
          ? 'Home'
          : accountStatus,
    };

    const queryTitle = 'updateUserProfile';
    try {
      const result = await graphqlApiDecorator(mutations, queryTitle, {
        input: BoUpdateUserProfileInput,
      });

      if (result) {
        setSavedHeader('Saved');
        setModalContent('Account status updated');
        setOpenSavedModal(true);
      }
    } catch (error) {
      setModalContent(error.message);
      setOpenWarningModal(true);
    }
  };

  useEffect(() => {
    walletList();
    getProfilePic();
    if (data?.kyc?.result === 'GREEN') setRiskRating('low');
    if (data?.kyc?.result === 'RED') setRiskRating('high');
  }, [data]);

  const alternativeCopyToClipboard = text => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };
  const copyToClipBoard = text => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      alternativeCopyToClipboard(text);
    }
  };

  const renderCopyToClipBoard = data => (
    <Tooltip title="Copy to clipboard">
      <div className={classes.clipBoardIcon} onClick={() => copyToClipBoard(data)}>
        <CopyToClipBoardIcon />
      </div>
    </Tooltip>
  );

  const formatSortCode = sortCode => {
    if (sortCode.length === 6) {
      return `${sortCode[0]}${sortCode[1]}-${sortCode[2]}${sortCode[3]}-${sortCode[4]}${sortCode[5]}`;
    }

    return sortCode;
  };

  const gbpSortCode = formatSortCode(getSortCodeFromIban(userIBAN));

  const walletList = async () => {
    const getUserWallets = 'getUserWallets';
    try {
      const result = await graphqlApiDecorator(queries, getUserWallets, { id: data.id });

      if (result.length === 0) {
        setDisableCloseBtn(false);
      } else {
        const hasBalance = result.some(wallet => wallet.balance > 0);
        setDisableCloseBtn(hasBalance);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmedResetPwCloseAccount = () => {
    setShowWarningModalCloseAccount(false);
    setShowLastWarningModalCloseAccount(true);
  };

  const lastconfirmedCloseAccount = async () => {
    const mutation = 'newCloseAccount';
    const input = { userId: data.id };
    try {
      const result = await graphqlApiDecorator(mutations, mutation, { input });
      if (result) {
        closedAccount(false);
        setShowLastWarningModalCloseAccount(false);
        if (result.success) {
          setErrorMsg('');
          setShowSuccessCloseAccountModal(true);
        } else {
          setErrorMsg(result.message);
          setShowLastWarningModalCloseAccount(false);
          setShowFaileCloseAccountModal(true);
        }
      }
    } catch (error) {
      setShowLastWarningModalCloseAccount(false);
      setShowFaileCloseAccountModal(true);
      closedAccount(false);
    }
  };

  const navigateUserProfile = userId => {
    navigate(`/${entitiesEnum.USERPROFILE.toLowerCase()}/${userId}`);

    window.location.reload();
  };

  return (
    <div className={classes.accountContainer}>
      <div className={classes.accountHeader}>
        <aside className={classes.accountLabel}>
          <SideRectangle />
          <header>Account</header>
        </aside>
      </div>
      <div className={classes.profileRow}>
        {profilePicUrl ? (
          <Avatar src={profilePicUrl} alt="example profile pic">
            {getAvatarInitials(data.name, data.lastName)}
          </Avatar>
        ) : (
          <DefaultAvatar />
        )}
        <div className={classes.lightBlueContainer}>
          <div className={classes.accountDetailsText}>
            <p>Member since:</p>
            <p>{getFormattedDisplayDate(data.createdAt)}</p>
          </div>
          <DottedDivider />
          <div className={classes.accountDetailsText}>
            <p>Internal Number:</p>
            <p>{data.customerId}</p>
          </div>
        </div>
      </div>
      <p style={{ margin: '8px 16px', fontSize: '14px', fontWeight: '500' }}>Account Details:</p>
      <section className={classes.ibanDetails}>
        <aside className={classes.ibanAccountDetailsLHS}>
          <div
            style={{
              width: '32px',
              height: '32px',
              borderRadius: '32px',
              backgroundColor: 'white',
            }}
          >
            <GBP width="32" height="32" />
          </div>
          <p style={{ fontWeight: '500', textAlign: 'center' }}>GBP</p>
        </aside>
        <div className={classes.verticalLine} />
        <aside className={classes.ibanAccountDetailsRHS}>
          <div>
            <p>Account No:</p>
            <p style={{ fontWeight: '500' }}>{getAccountNoFromIban(iban)}</p>
            {renderCopyToClipBoard(getAccountNoFromIban(iban))}
          </div>
          <div>
            <p>Sort Code:</p>
            <p style={{ fontWeight: '500' }}>{getSortCodeFromIban(iban)}</p>
            {renderCopyToClipBoard(getSortCodeFromIban(iban))}
          </div>
          <div>
            <p>IBAN:</p>
            <p style={{ fontWeight: '500' }}>{iban}</p>
            {renderCopyToClipBoard(iban)}
          </div>
        </aside>
      </section>
      <div className={classes.dashedLine} style={{ margin: '16px' }} />
      <div className={classes.membershipDetails}>
        <div className={classes.membershipPlan}>
          <div>
            {membershipPlan === 'elite' ? (
              <>
                <EliteIcon />
                <p>Elite</p>
              </>
            ) : (
              <>
                <EliteIcon active={false} />
                <p style={normalIconText}>Elite</p>
              </>
            )}
          </div>
          <div>
            {membershipPlan === 'premium' ? (
              <>
                <PremiumIcon />
                <p>Premium</p>
              </>
            ) : (
              <>
                <PremiumIcon active={false} />
                <p style={normalIconText}>Premium</p>
              </>
            )}
          </div>
          <div>
            {membershipPlan === 'standard' ? (
              <>
                <StandardIcon />
                <p>Standard</p>
              </>
            ) : (
              <>
                <StandardIcon active={false} />
                <p style={normalIconText}>Standard</p>
              </>
            )}
          </div>
        </div>
        {/* <div className={classes.deviceDetails}>
          <div>
            <IPIcon />
            <p>123.45.67.87</p>
          </div>
          <div>
            <LocationIcon />
            <p>London</p>
          </div>
          <div>
            <DeviceIcon />
            <p>MacBook Pro</p>
          </div>
        </div> */}
        <div className={classes.accountType}>
          <div>
            {accountType === 'personal' ? (
              <>
                <PersonalIcon />
                <p>Personal</p>
              </>
            ) : (
              <>
                <PersonalIcon active={false} />
                <p style={normalIconText}>Personal</p>
              </>
            )}
          </div>
          <div>
            {accountType === 'company' ? (
              <>
                <BusinessIcon />
                <p>Business</p>
              </>
            ) : (
              <>
                <BusinessIcon active={false} />
                <p style={normalIconText}>Business</p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.dashedLine} />
      <div>
        <div className={classes.accountStatusTitle}>
          <p style={{ marginLeft: '16px' }}>Account Status</p>
        </div>
        <div className={classes.accountStatus}>
          <div className={classes.statusTreeContainer}>
            <div className={classes[accountStatus] || classes.rejected}>
              <AccountStatus status={accountStatus} />
            </div>
            {accountStatus !== 'closed' && (
              <div className={classes.closedTarget}>
                <InactiveStatus />
              </div>
            )}
            {accountStatus !== 'active' && (
              <div className={classes.activeTarget}>
                <InactiveStatus />
              </div>
            )}
            {accountStatus !== 'suspended' && (
              <div className={classes.suspendedTarget}>
                <InactiveStatus />
              </div>
            )}
            {accountStatus !== 'account_verification_failed' &&
              accountStatus !== 'hard_failed' &&
              accountStatus !== 'soft_failed' && (
                <div className={classes.rejectedTarget}>
                  <InactiveStatus />
                </div>
              )}
            {accountStatus !== 'created' && (
              <div className={classes.createdTarget}>
                <InactiveStatus />
              </div>
            )}
            <div
              className={classes.rejectedText}
              style={
                accountStatus === 'account_verification_failed' ||
                accountStatus === 'soft_failed' ||
                accountStatus === 'hard_failed'
                  ? boldText
                  : greyText
              }
            >
              Rejected
            </div>
            <div
              className={classes.activeText}
              style={accountStatus === 'active' ? boldText : greyText}
            >
              Active
            </div>
            <div
              className={classes.createdText}
              style={accountStatus === 'created' ? boldText : greyText}
            >
              Created
            </div>
            <div
              className={classes.closedText}
              style={accountStatus === 'closed' ? boldText : greyText}
            >
              Closed
            </div>
            <div
              className={classes.suspendedText}
              style={accountStatus === 'suspended' ? boldText : greyText}
            >
              Suspended
            </div>
            <img src={accountStatusTree} alt="Account status tree" />
          </div>
        </div>
        <div className={classes.actionBtnContainer}>
          <section>
            <Button
              sx={{
                width: '100%',
                height: '32px',
                border: '1px solid red',
                color: '#093560',
                borderRadius: '10px',
                marginTop: '30px',
                textTransform: 'none',
              }}
              disabled={
                disableCloseBtn ||
                data.accountStatus === 'closed' ||
                data.accountStatus === 'created'
              }
              onClick={() => setShowWarningModalCloseAccount(true)}
            >
              <p>{data.accountStatus === 'closed' ? 'This account is closed' : 'Close Account'}</p>
            </Button>
          </section>
          {data.accountStatus !== 'closed' && (
            <section>
              <Button
                sx={{
                  width: '100%',
                  height: '32px',
                  border: '1px solid gray',
                  color: '#093560',
                  borderRadius: '10px',
                  marginTop: '30px',
                  textTransform: 'none',
                }}
                onClick={() => {
                  if (data.accountStatus === 'suspended') {
                    setNewStatuse('active');
                  } else {
                    setNewStatuse('suspended');
                  }
                  setShowWarningModalSusspendAccount(true);
                }}
              >
                <p>{data.accountStatus === 'suspended' ? 'Activate Account' : 'Suspend Account'}</p>
              </Button>
            </section>
          )}
        </div>
      </div>

      <div className={classes.dashedLine} />
      <div className={classes.riskRating}>
        <div className={classes.riskRatingAndStatusTitle}>
          <p style={{ marginLeft: '16px' }}>Risk Rating</p>
        </div>
        <div>
          <GaugeChart riskRate={currentRiskLevel} type={'account'} loading={loading} />
        </div>
      </div>
      <div className={classes.detailBtnContainer}>
        <Button
          sx={{
            width: '100%',
            height: '32px',
            padding: '0',
            borderRadius: '16px',
            textTransform: 'none',
            marginTop: '10px',
          }}
          onClick={handleOpenCustomerFilterModal}
        >
          <p className={classes.detailBtn}>Details</p>
        </Button>
      </div>
      <SavedModal
        open={openSavedModal}
        onClose={() => {
          setShowWarningModalSusspendAccount(false);
          setOpenSavedModal(false);
          navigateUserProfile(data.id);
        }}
        header={savedHeader}
        content={modalContent}
      />
      <WarningModal
        open={openWarningModal}
        onClose={() => setOpenWarningModal(false)}
        confirm={() => setOpenWarningModal(false)}
        message={modalContent}
      />
      <WarningModal
        open={showWarningModalCloseAccount}
        onClose={() => setShowWarningModalCloseAccount(false)}
        confirm={confirmedResetPwCloseAccount}
        message={`Are you sure about closing ${data.name || ''} ${data.lastName || ''} account?`}
        btnText={'Yes'}
      />
      <WarningModal
        open={showWarningModalSusspendAccount}
        onClose={() => setShowWarningModalSusspendAccount(false)}
        confirm={saveUserProfile}
        message={`Are you sure about ${
          accountStatus === 'suspended' ? 'activating' : 'suspending'
        } ${data.name || ''} ${data.lastName || ''} account?`}
        btnText={'Yes'}
      />
      <DeleteModal
        open={showLastWarningModalCloseAccount}
        cancel={() => setShowLastWarningModalCloseAccount(false)}
        deleteIt={lastconfirmedCloseAccount}
        onClose={() => setShowLastWarningModalCloseAccount(false)}
        header={'Close Account'}
        content={`You can close ${data.name || ''} ${data.lastName || ''} account after 5 seconds.`}
        buttonEnabled={false}
      />
      <SavedModal
        open={showSuccessCloseAccountModal}
        onClose={() => {
          setShowSuccessCloseAccountModal(false);
          navigateUserProfile(data.id);
        }}
        header={'Close Account'}
        content={'Account closed successfully'}
      />
      <WarningModal
        open={showFaileCloseAccountModal}
        onClose={() => setShowFaileCloseAccountModal(false)}
        confirm={() => setShowFaileCloseAccountModal(false)}
        header={'Close Account'}
        message={errorMsg}
      />
      <Modal open={openCustomerFilterModal} onClose={() => setOpenCustomerFilterModal(false)}>
        <Box>
          <RiskModal onClose={() => setOpenCustomerFilterModal(false)} userId={id} />
        </Box>
      </Modal>
    </div>
  );
};
